.tableContainer{
    border-radius: 20px;
    padding: 0 10px 10px 10px;
    overflow: scroll;
}

Table thead{
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    background-color: white;
}

/* table {
    table-layout: fixed;
  } */

/* td,
tr {
    width: 1px;
    white-space: nowrap;
} */

.carousel-slider {
    height: 700px;
}

@media (max-width: 800px) {
    .fc .fc-toolbar.fc-header-toolbar {
        display: flex;
        flex-wrap: wrap;
    }

    .fc-header-toolbar .fc-toolbar-chunk {
        display: block;
        /* width: 100% !important; */
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .fc-header-toolbar .fc-toolbar-chunk:nth-of-type(1){
      width: 100%;
      text-align: center
    }
}

input[disabled] {pointer-events:none}


*:focus {
    outline: none !important;
    background-color: white !important;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.listcontainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.listcontainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}




  
  .marquee {
    display: inline-block;
    width: max-content;
  
    /* padding-left: 100%; */
    /* show the marquee just outside the paragraph */
    will-change: transform;
    animation: marquee 7s linear infinite;
  }
  
  .marquee :hover {
    animation-play-state: paused
  }

  .marquee :focus {
    animation-play-state: paused
  }
  
  
  @keyframes marquee {
    /* 0% { transform: translate(0, 0); } */
    100% { transform: translate(-100%, 0); }
  }

  @media (prefers-reduced-motion: reduce) {
    .marquee {
      animation-iteration-count: 1;
      animation-duration: 0.01; 
      /* instead of animation: none, so an animationend event is 
       * still available, if previously attached.
       */
      width: auto;
      padding-left: 0;
    }
  }

span.cap:first-letter {
    text-transform: uppercase;
}


/* body {
  background-position:center;
  background-image:url('https://cdn.vox-cdn.com/thumbor/YzsmXXP_b5x06kyDSPNKpGY4IKM=/0x0:4816x3211/1200x800/filters:focal(2023x1221:2793x1991)/cdn.vox-cdn.com/uploads/chorus_image/image/73558866/usa_today_21204246.0.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  background-attachment: fixed;
  
  height: 300vh;
} */

#bg {
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100vw; 
  height: 100vh;
}
/* #bg img {
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  margin: auto; 
  min-width: 50%;
  min-height: 50%;
} */